<template>
  <div>
    <ChartVisitorDialogContent
    ></ChartVisitorDialogContent>
  </div>
</template>

<script>
import ChartVisitorDialogContent from "@/components/public-chart/ChartVisitorDialogContent.vue";
export default {
  components: {
    ChartVisitorDialogContent
  },
  setup() {
    return {
      //
    }
  }
}
</script>

<style scoped>

</style>